import store from '@/store';
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { getAdminConfig, setAdminConfig, removeAdminConfig } from '@/utils/cookies';

@Module({ dynamic: true, store, name: 'appConfig' })
class appConfig extends VuexModule {
    // 首页基础信息
    public appConfig: any = getAdminConfig() || {};

    public networkLine: any = localStorage.getItem('networkLine') || '0';

    @Mutation
    private SET_APP_CONFIG(data: object) {
        this.appConfig = data;
        setAdminConfig(data);
    }

    @Mutation
    private SET_NETWORK_LINE(str: string) {
        this.networkLine = str;
        localStorage.setItem('networkLine', str);
    }

    @Action
    public setAppConfig() {
        // const url = process.env.NODE_ENV == 'development' ? 'http://192.168.1.42:9001/config.json' : '/config.json';
        // const url = process.env.NODE_ENV === 'development' ? 'https://test.feimajy.com/config.json' : '/config.json';
        const url = process.env.NODE_ENV === 'development' ? '/config.json' : '/config.json';
        return new Promise((resolve) => {
            fetch(url, {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }).then(response => response.json()).then(data => {
                const useLine = data.PcAPi?.[this.networkLine] ?? data['PCApi' + this.networkLine];
                data.PCRequestApi = useLine;
                this.SET_APP_CONFIG(data);
                resolve(data);
            }).catch(() => {
                removeAdminConfig();
            });
        });
    }

    @Action
    public async setNetworkLine(str) {
        this.SET_NETWORK_LINE(str);
        await this.setAppConfig();
        window.location.reload();
    }

}
export const appConfigModule = getModule(appConfig);

/*
appConfig 参数
{
    "vueShopName": "臺集運", // 店铺名称
    "language1": "tw",
    "language2": "tw",
    "language3": "zh-Hant",
    "vueAdmin": "https://testtjy.lanyuechuhai.com",
    "vueSrc": "https://testtjy.lanyuechuhai.com/images/3", // 访问的地址图片
    "PCRequestApi": "",
    "PCApi0": "https://testapi.paijiangjy.com",
    "PCApi1": "https://testapi.paijiangjy.com",
    "PCApi2": "https://testgateway.lanyuechuhai.com",
    "vueHkApi": "https://testgateway.lanyuechuhai.com",
    "H5RequestApi": "",
    "H5Api0": "https://testapi.paijiangjy.com",
    "H5Api1": "https://testapi.paijiangjy.com",
    "H5Api2": "https://testgateway.lanyuechuhai.com",
    "H5HkApi": "https://testgateway.lanyuechuhai.com",
    "vueYm": "https://testtjy.lanyuechuhai.com", // 返回主页面域名
    "lineUrl": "https://testtjy.lanyuechuhai.com/H5", // h5 第三方登录域名
    "wxUrl": "https://testtjy.lanyuechuhai.com/H5", // h5 第三方登录域名
    "shopId": 3 // 店铺id
}
*/
